/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  return (
    <>
      <Meta
        title="Changelog"
        description="Get the latest news about the BB-Code: QR Code."
      />
      <Hero
        title="Changelog"
        subtitle="Get the latest news about BB-Code: QR Code."
      />
      <div className="section">
        <div className="container">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <span className="tag is-medium">Version 1.0.0</span>
            </header>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag">1.0.1</span>{" "}
                  <time dateTime="2022-03-20T00:18:04+01:00">
                    20th March 2022
                  </time>
                </p>
                <p>
                  <span className="has-text-success">&bull;</span> A foreground
                  and background color can now be defined. The BBCode is
                  structured as follows: Content, Foreground-Color,
                  Background-Color.
                  <br />
                  Example:{" "}
                  <code>
                    [qrcode='Your QR-Code – Content
                    ','#fcba03','#fc03f4'][/qrcode]
                  </code>
                  <br />
                  <br />
                  <span className="has-text-warning">&bull;</span> The display
                  of the QR code has been changed.
                  <br />
                  <span className="has-text-warning">&bull;</span> Fixed an
                  error that generated a Fatal Error if the content of the
                  BBCode consisted only of blank lines.
                </p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker is-primary"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag is-primary">1.0.2</span>{" "}
                  <time dateTime="2022-03-30T10:52:36+02:00">
                    30th March 2022
                  </time>
                </p>
                <p>
                  <span className="has-text-warning">&bull;</span> Fixed an
                  incorrect regular expression which did not allow transparency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangelogPage;
