/**
 * @file index.jsx
 * @description Home page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import MediumHero from "../components/mediumHero";
import Impressions from "../components/impressions";
import Testimonials from "../components/testimonials";

/**
 * @function IndexPage
 * @returns {JSX.Element} Home page.
 */

function IndexPage() {
  return (
    <>
      <Meta
        title="Home"
        description="Adds a “QR Code – BB-Code“ to the WoltLab Suite™."
      />
      <MediumHero
        title="BB-Code: QR Code"
        subtitle="Adds a “QR Code – BB-Code“ to the WoltLab Suite™."
        buttonText="Download"
        buttonLink="/download"
      />
      <div className="section">
        <div className="container">
          <h2 className="title is-4">Simple creation of a QR Code</h2>
          <p>
            With the help of this BBCode you can easily create a QR Code. Use
            the following BBCode to create it:
            <br />
            <br />
          </p>
          <code>[qrcode]Your QR-Code – Content[/qrcode]</code>
          <Impressions />
          <h2 className="title is-4">Extended creation of a QR Code</h2>
          <p>
            You can also create a QR Code with additional parameters, for
            example, to change the foreground color and the background color.
            Use the following BBCode to create it:
            <br />
            <br />
          </p>
          <code>
            [qrcode='Dein QR-Code – Inhalt ','#fcba03','#fc03f4'][/qrcode]
          </code>
          <br />
          <br />
          <figure className="image is-48x48">
            <img
              src="/images/qrcode.png"
              alt="QR Code"
              loading="lazy"
              height="45"
              width="45"
            />
          </figure>
          <Testimonials />
        </div>
      </div>
    </>
  );
}

export default IndexPage;
